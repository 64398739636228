import React, { useContext, useEffect, useState } from 'react'
import { UserDrawer } from '../../components/layout'
import { Card, Button, TextField, CardActions, CardContent, Typography, Divider } from "@material-ui/core";
import { ListItemAvatar, ListItemText, List, Avatar, ListItem } from '@material-ui/core';
import { AlertsContext, TopicsStoreContext } from '../../state'
import path_for from '../path_for'
import { Topic, ConfigEntry } from '../../client'
import { ErrorMessage } from '../../utils'
import { useFormik } from "formik";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { RouteComponentProps } from 'react-router-dom';
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import MoreIcon from "@material-ui/icons/More";
import { PrettyMilliseconds } from '../../components/prettier';

type TopicFormState = {
  name: string;
  retentionMs: number;
  retentionBytes: number;
  segmentBytes: number;
  messageTimestampType: string;
};

const InitialTopicFormState: TopicFormState = {
  name: "",
  retentionMs: 604800000,   // 7 days
  retentionBytes: -1,       // no limit
  segmentBytes: 1073741824, // 1GB
  messageTimestampType: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));

const getConfig = (t: Topic, cfgName: string): ConfigEntry | undefined => {
  return t.configEntries && t.configEntries!.find(e => e.configName === cfgName)
}

const getConfigNumberValue = (t: Topic, cfgName: string): number | undefined => {
  const cfg = getConfig(t, cfgName);
  if (cfg === undefined) {
    return undefined;
  }
  if (cfg!.configValue === undefined) {
    return undefined;
  }
  const num = parseInt(cfg!.configValue!);
  if (isNaN(num)) {
    return undefined;
  }
  return num
}

const getConfigStringValue = (t: Topic, cfgName: string): string | undefined => {
  const cfg = getConfig(t, cfgName);
  if (cfg === undefined) {
    return undefined;
  }
  if (cfg!.configValue === undefined) {
    return undefined;
  }
  return cfg!.configValue!
}

const toFormState = (t: Topic): TopicFormState => {
  return {
    name: t.name!,
    retentionMs: getConfigNumberValue(t, "retention.ms")!,
    retentionBytes: getConfigNumberValue(t, "retention.bytes")!,
    segmentBytes: getConfigNumberValue(t, "segment.bytes")!,
    messageTimestampType: getConfigStringValue(t, "message.timestamp.type")!,
  };
}

type TopicsProps = Pick<RouteComponentProps<{ name?: string }>, 'history' | 'match'>

const TopicForm = (props: TopicsProps) => {
  const name = props.match.params.name

  const [currentTopic, setCurrentTopic] = useState<Topic | null>(null)
  const { state } = useContext(TopicsStoreContext);
  const [isLoading, setIsLoading] = useState(true)
  const { dispatch: showAlert } = useContext(AlertsContext);
  const classes = useStyles();

  const initialValues: TopicFormState = currentTopic ? toFormState(currentTopic) : InitialTopicFormState

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values: TopicFormState, helpers) => {
      console.log("Update topic: ", JSON.stringify(values, null, 2));
      helpers.setSubmitting(true);
      state.api
        .kafkaccServiceUpdateTopic(name!, {
          name: name!,
          validateOnly: false,
          retentionMs: values.retentionMs.toString(),
          retentionBytes: values.retentionBytes.toString(),
          segmentBytes: values.segmentBytes.toString(),
          messageTimestampType: values.messageTimestampType,
        })
        .then((response) => {
          setCurrentTopic(response)
          helpers.setSubmitting(false);
        })
        .catch((err) => {
          ErrorMessage(err).then((msg) => {
            showAlert({ type: "error", message: msg });
          });
          helpers.setSubmitting(false);
        })
    }
  });

  useEffect(() => {
    if (name === null || name === undefined) {
      return
    }
    setIsLoading(true)
    state.api
      .kafkaccServiceGetTopic(name)
      .then(resp => {
        setCurrentTopic(resp)
        setIsLoading(false)
      })
      .catch(err => {
        ErrorMessage(err).then((msg) => {
          showAlert({ type: "error", message: msg });
        });
        setIsLoading(false)
      })
  }, [state.api, name, showAlert])

  return (
    <UserDrawer breadcrumbs={[['KafkaCC', path_for.Home()], ['Topics', path_for.Topics()], 'Edit']}>
      {isLoading ? 'Loading data for topic...' :
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h4" component="h4">
              Topic: {currentTopic?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit} className={classes.root}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item container spacing={1} xs={8}>
                      <Grid item xs={6}>
                        <TextField
                          id="retentionBytes"
                          name="retentionBytes"
                          label="Retention Bytes"
                          fullWidth
                          style={{ width: 200 }}
                          value={formik.values.retentionBytes}
                          onChange={formik.handleChange}
                          error={formik.touched.retentionBytes && Boolean(formik.errors.retentionBytes)}
                          helperText={formik.values.retentionBytes > 0 ? formik.values.retentionBytes / 1024 / 1024 + " MB" : "Not restricted"}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="retentionMs"
                          name="retentionMs"
                          label="Retention Ms"
                          fullWidth
                          style={{ width: 200 }}
                          value={formik.values.retentionMs}
                          onChange={formik.handleChange}
                          error={formik.touched.retentionMs && Boolean(formik.errors.retentionMs)}
                          helperText={formik.values.retentionMs > 0 ? PrettyMilliseconds(formik.values.retentionMs) : "Not restricted"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="segmentBytes"
                          name="segmentBytes"
                          label="Segment Bytes"
                          fullWidth
                          style={{ width: 200 }}
                          value={formik.values.segmentBytes}
                          onChange={formik.handleChange}
                          error={formik.touched.segmentBytes && Boolean(formik.errors.segmentBytes)}
                          helperText={formik.values.segmentBytes / 1024 / 1024 + " MB"}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="messageTimestampType"
                          name="messageTimestampType"
                          label="Message Timestamp Type"
                          fullWidth
                          style={{ width: 200 }}
                          value={formik.values.messageTimestampType}
                          onChange={formik.handleChange}
                          error={formik.touched.messageTimestampType && Boolean(formik.errors.messageTimestampType)}
                          helperText="CreateTime | LogAppendTime"
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <ul>
                        {currentTopic && currentTopic.configEntries!
                          .map((cfgEntry) => (
                            <li key={cfgEntry.configName}>
                              {cfgEntry.readOnly ? 'ReadOnly: ' : ''}
                              {cfgEntry.configName}: {cfgEntry.configValue}
                            </li>
                          ))}
                      </ul>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button type="submit" variant="contained">
                    Update topic
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" variant="h5" component="h2">
                  Topic offsets
                </Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <FirstPageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="First offset" secondary={currentTopic?.firstOffset} />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LastPageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Last offset" secondary={currentTopic?.lastOffset} />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <MoreIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Total messages" secondary={currentTopic && (+currentTopic.lastOffset! - +currentTopic.firstOffset!)} />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" variant="h5" component="h2">
                  Topic timestamps
                </Typography>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <FirstPageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="First timestamp" secondary={currentTopic?.firstTimestamp} />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <LastPageIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Last timestamp" secondary={currentTopic?.lastTimestamp} />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" variant="h5" component="h2">
                  Partition offsets
                </Typography>
                <List>
                  {currentTopic && currentTopic.partitions && currentTopic.partitions
                    .sort((a, b) => +a.id! - +b.id!)
                    .map<React.ReactNode>((partition) => {
                      return (
                        <ListItem key={partition.id!}>
                          <ListItemAvatar>
                            <Avatar>
                              <Typography color="textSecondary" variant="h5" component="h2">
                                {partition.id}
                              </Typography>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary={partition.firstOffset + " : " + partition.lastOffset} />
                        </ListItem>
                      )
                    })
                    .reduce((prev, curr, ind) => {
                      return [
                        prev,
                        < Divider variant="inset" component="li" key={"div" + ind} />,
                        curr
                      ]
                    })
                  }
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </UserDrawer >
  )
}

export default TopicForm;