const ErrorMessage = async (err: Error | Response): Promise<string> => {
  switch (err.constructor) {
    case Response:
      const resp = err as Response;
      const msg = await resp
        .json()
        .then(j => j.message || j.error)
        .catch(err => err);
      return Promise.resolve(msg);

    default:
      return Promise.resolve(String(err));
  }
};

export default ErrorMessage;