import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import { Container, Box, Typography } from "@material-ui/core";
import { SessionStoreContext } from "../../state";
import { UserDrawer } from "../../components/layout";

export default withRouter(({ history }) => {
  const { state: session } = useContext(
    SessionStoreContext
  );

  return (
    <UserDrawer breadcrumbs={['KafkaCC']}>
      <Container maxWidth="sm">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Kafka Control Center
          </Typography>
          <p>Welcome {session.sessionInfo.email}!</p>
        </Box>
      </Container>
    </UserDrawer>
  );
});
