import React from "react";

const NotFound = function () {
  return (
    <p>
      <h1>We have not found what you are looking for, sorry...</h1>
    </p>
  );
}

export default NotFound;