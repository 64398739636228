const Home = () => `/`

const Login = () => `/login`
const Auth = (provider: string) => `/auth/${provider}`

const Topics = (name?: string) => (name ? `/topics/${name}` : `/topics`)
const NewTopic = () => `/topics/new`

const Paths = {
  Home,
  Login,
  Auth,

  Topics,
  NewTopic,
}
export default Paths;