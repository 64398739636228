import { createStyles, Theme } from "@material-ui/core/styles";
import { green, amber } from "@material-ui/core/colors";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    error: {
      backgroundColor: palette.error.light
    },
    success: {
      backgroundColor: green[600]
    },
    warning: {
      backgroundColor: amber[700]
    },
    info: {
      backgroundColor: palette.primary.main
    },
    icon: {
      fontSize: 20
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: spacing(1)
    },
    message: {
      display: "flex",
      alignItems: "center"
    }
  });

  export default styles;