import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  RouteProps,
  RouteComponentProps,
  Route,
  Redirect,
  StaticContext,
} from "react-router";
import { SessionStoreContext, AlertsContext } from "../state";
import { ErrorMessage } from "../utils";

interface PrivateRouteProps extends RouteProps {
  component:
  | React.ComponentType<RouteComponentProps<any>>
  | React.ComponentType<any>;
}

type PrivateRouteState = {
  renderer: (
    props: RouteComponentProps<any, StaticContext, any>
  ) => JSX.Element;
};

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  // const { path } = rest;
  const { state: session, dispatch: authenticate } = useContext(
    SessionStoreContext
  );
  const { dispatch: showAlert } = useContext(AlertsContext);

  const [state, setState] = useState<PrivateRouteState>({ renderer: waitStub });

  function waitStub(props: RouteComponentProps<any, StaticContext, any>) {
    return <></>;
  }

  const privateContent = useCallback(
    (props: RouteComponentProps<any, StaticContext, any>) => {
      return <Component {...props} />;
    },
    [Component]
  );

  function redirectToLogin(
    props: RouteComponentProps<any, StaticContext, any>
  ) {
    return <Redirect to="/login" />;
  }

  useEffect(() => {
    switch (true) {
      case session.authenticated:
        setState({ renderer: privateContent });
        break;

      case session.api !== null:
        // Try to get sessionInfo using existing cookie
        session.api
          .kafkaccServiceMe(session.requestOptions())
          .then((sessionInfo) => {
            authenticate({ type: "success", sessionInfo: sessionInfo });
            setState({ renderer: privateContent });
          })
          .catch((err) => {
            ErrorMessage(err).then((msg) => {
              authenticate({ type: "error", error: msg });
              setState({ renderer: redirectToLogin });
            });
          });
        break;

      default:
        setState({ renderer: redirectToLogin });
        break;
    }
  }, [
    session.authenticated,
    authenticate,
    showAlert,
    session,
    privateContent,
  ]);

  return <Route {...rest} render={state.renderer} />;
};

export default PrivateRoute;
