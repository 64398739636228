import React from "react";
import { Topic } from "../../client/api";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

type HandleClickFn = (e: React.MouseEvent<any>, topic: Topic) => void

export default function TopicsTable({
  topics,
  onClick
}: {
  topics: Topic[],
  onClick?: HandleClickFn
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 100 }}>
                Name of the topic
              </TableCell>
              <TableCell style={{ minWidth: 200 }}>
                Current config settings
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topics.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((topic) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={topic.name}
                  onClick={e => onClick && onClick(e, topic)}>
                  <TableCell align='center'>
                    {topic.name}
                  </TableCell>
                  <TableCell align='left'>
                    <ul>
                      {topic.configEntries!
                        .map((cfgEntry) => (
                          <li key={cfgEntry.configName}>
                            {cfgEntry.readOnly ? 'ReadOnly: ' : ''}
                            {cfgEntry.configName}: {cfgEntry.configValue}
                          </li>
                        ))}
                    </ul>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={topics.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper >
  );
}
