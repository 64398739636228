import PathFor from './path_for'
import Topics from "./topics";
import Home from "./home";
import NotFound from "./notfound";
import Sessions from "./sessions";

const Routes = {
  PathFor,
  Sessions,
  Topics,
  Home,
  NotFound,
};

export default Routes;