/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * KafkaCC
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AuthURLResponse
 */
export interface AuthURLResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthURLResponse
     */
    authURL?: string;
}

/**
 * 
 * @export
 * @interface ConfigEntry
 */
export interface ConfigEntry {
    /**
     * 
     * @type {string}
     * @memberof ConfigEntry
     */
    configName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigEntry
     */
    configValue?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigEntry
     */
    readOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigEntry
     */
    isDefault?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ConfigEntry
     */
    configSource?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigEntry
     */
    isSensitive?: boolean;
    /**
     * 
     * @type {Array<ConfigSynonym>}
     * @memberof ConfigEntry
     */
    configSynonyms?: Array<ConfigSynonym>;
    /**
     * 
     * @type {number}
     * @memberof ConfigEntry
     */
    configType?: number;
    /**
     * 
     * @type {string}
     * @memberof ConfigEntry
     */
    configDocumentation?: string;
}

/**
 * 
 * @export
 * @interface ConfigSynonym
 */
export interface ConfigSynonym {
    /**
     * 
     * @type {string}
     * @memberof ConfigSynonym
     */
    configName?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigSynonym
     */
    configValue?: string;
    /**
     * 
     * @type {number}
     * @memberof ConfigSynonym
     */
    configSource?: number;
}

/**
 * 
 * @export
 * @interface ListTopicsResponse
 */
export interface ListTopicsResponse {
    /**
     * 
     * @type {Array<Topic>}
     * @memberof ListTopicsResponse
     */
    topics?: Array<Topic>;
    /**
     * 
     * @type {string}
     * @memberof ListTopicsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof ListTopicsResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    authQuery?: string;
}

/**
 * 
 * @export
 * @interface Partition
 */
export interface Partition {
    /**
     * 
     * @type {string}
     * @memberof Partition
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Partition
     */
    firstOffset?: string;
    /**
     * 
     * @type {string}
     * @memberof Partition
     */
    lastOffset?: string;
}

/**
 * 
 * @export
 * @interface ProtobufAny
 */
export interface ProtobufAny {
    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    typeUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProtobufAny
     */
    value?: string;
}

/**
 * 
 * @export
 * @interface RpcStatus
 */
export interface RpcStatus {
    /**
     * 
     * @type {number}
     * @memberof RpcStatus
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof RpcStatus
     */
    message?: string;
    /**
     * 
     * @type {Array<ProtobufAny>}
     * @memberof RpcStatus
     */
    details?: Array<ProtobufAny>;
}

/**
 * 
 * @export
 * @interface SessionInfo
 */
export interface SessionInfo {
    /**
     * 
     * @type {string}
     * @memberof SessionInfo
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionInfo
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface Topic
 */
export interface Topic {
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    name?: string;
    /**
     * 
     * @type {Array<ConfigEntry>}
     * @memberof Topic
     */
    configEntries?: Array<ConfigEntry>;
    /**
     * 
     * @type {Array<Partition>}
     * @memberof Topic
     */
    partitions?: Array<Partition>;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    firstOffset?: string;
    /**
     * 
     * @type {string}
     * @memberof Topic
     */
    lastOffset?: string;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    firstTimestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Topic
     */
    lastTimestamp?: Date;
}

/**
 * 
 * @export
 * @interface UpdateTopicRequest
 */
export interface UpdateTopicRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTopicRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTopicRequest
     */
    validateOnly?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTopicRequest
     */
    retentionMs?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTopicRequest
     */
    retentionBytes?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTopicRequest
     */
    segmentBytes?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTopicRequest
     */
    messageTimestampType?: string;
}


/**
 * KafkaccServiceApi - fetch parameter creator
 * @export
 */
export const KafkaccServiceApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceAuthURL(provider?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth_url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceGetTopic(name: string, options: any = {}): FetchArgs {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling kafkaccServiceGetTopic.');
            }
            const localVarPath = `/v1/topics/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Topics
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceListTopics(pageSize?: number, pageToken?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/topics`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceLogin(body: LoginRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling kafkaccServiceLogin.');
            }
            const localVarPath = `/v1/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceMe(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateTopicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceUpdateTopic(name: string, body: UpdateTopicRequest, options: any = {}): FetchArgs {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling kafkaccServiceUpdateTopic.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling kafkaccServiceUpdateTopic.');
            }
            const localVarPath = `/v1/topics/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KafkaccServiceApi - functional programming interface
 * @export
 */
export const KafkaccServiceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceAuthURL(provider?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthURLResponse> {
            const localVarFetchArgs = KafkaccServiceApiFetchParamCreator(configuration).kafkaccServiceAuthURL(provider, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceGetTopic(name: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Topic> {
            const localVarFetchArgs = KafkaccServiceApiFetchParamCreator(configuration).kafkaccServiceGetTopic(name, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Topics
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceListTopics(pageSize?: number, pageToken?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ListTopicsResponse> {
            const localVarFetchArgs = KafkaccServiceApiFetchParamCreator(configuration).kafkaccServiceListTopics(pageSize, pageToken, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceLogin(body: LoginRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionInfo> {
            const localVarFetchArgs = KafkaccServiceApiFetchParamCreator(configuration).kafkaccServiceLogin(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceMe(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SessionInfo> {
            const localVarFetchArgs = KafkaccServiceApiFetchParamCreator(configuration).kafkaccServiceMe(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateTopicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceUpdateTopic(name: string, body: UpdateTopicRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Topic> {
            const localVarFetchArgs = KafkaccServiceApiFetchParamCreator(configuration).kafkaccServiceUpdateTopic(name, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * KafkaccServiceApi - factory interface
 * @export
 */
export const KafkaccServiceApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceAuthURL(provider?: string, options?: any) {
            return KafkaccServiceApiFp(configuration).kafkaccServiceAuthURL(provider, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceGetTopic(name: string, options?: any) {
            return KafkaccServiceApiFp(configuration).kafkaccServiceGetTopic(name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Topics
         * @param {number} [pageSize] 
         * @param {string} [pageToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceListTopics(pageSize?: number, pageToken?: string, options?: any) {
            return KafkaccServiceApiFp(configuration).kafkaccServiceListTopics(pageSize, pageToken, options)(fetch, basePath);
        },
        /**
         * 
         * @param {LoginRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceLogin(body: LoginRequest, options?: any) {
            return KafkaccServiceApiFp(configuration).kafkaccServiceLogin(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceMe(options?: any) {
            return KafkaccServiceApiFp(configuration).kafkaccServiceMe(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} name 
         * @param {UpdateTopicRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kafkaccServiceUpdateTopic(name: string, body: UpdateTopicRequest, options?: any) {
            return KafkaccServiceApiFp(configuration).kafkaccServiceUpdateTopic(name, body, options)(fetch, basePath);
        },
    };
};

/**
 * KafkaccServiceApi - object-oriented interface
 * @export
 * @class KafkaccServiceApi
 * @extends {BaseAPI}
 */
export class KafkaccServiceApi extends BaseAPI {
    /**
     * 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaccServiceApi
     */
    public kafkaccServiceAuthURL(provider?: string, options?: any) {
        return KafkaccServiceApiFp(this.configuration).kafkaccServiceAuthURL(provider, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaccServiceApi
     */
    public kafkaccServiceGetTopic(name: string, options?: any) {
        return KafkaccServiceApiFp(this.configuration).kafkaccServiceGetTopic(name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Topics
     * @param {number} [pageSize] 
     * @param {string} [pageToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaccServiceApi
     */
    public kafkaccServiceListTopics(pageSize?: number, pageToken?: string, options?: any) {
        return KafkaccServiceApiFp(this.configuration).kafkaccServiceListTopics(pageSize, pageToken, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {LoginRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaccServiceApi
     */
    public kafkaccServiceLogin(body: LoginRequest, options?: any) {
        return KafkaccServiceApiFp(this.configuration).kafkaccServiceLogin(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaccServiceApi
     */
    public kafkaccServiceMe(options?: any) {
        return KafkaccServiceApiFp(this.configuration).kafkaccServiceMe(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} name 
     * @param {UpdateTopicRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KafkaccServiceApi
     */
    public kafkaccServiceUpdateTopic(name: string, body: UpdateTopicRequest, options?: any) {
        return KafkaccServiceApiFp(this.configuration).kafkaccServiceUpdateTopic(name, body, options)(this.fetch, this.basePath);
    }

}

