import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AlertsContext, SessionStoreContext } from "../../state";
import { ErrorMessage } from "../../utils";

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext);
  const { state: session, dispatch: authenticate } = useContext(
    SessionStoreContext
  );

  useEffect(() => {
    session.api
      .kafkaccServiceLogin({
        provider: match.params.provider,
        authQuery: history.location.search,
      })
      .then((sessionInfo) => {
        authenticate({ type: "success", sessionInfo: sessionInfo });
        showAlert({
          type: "success",
          message: `Welcome ${sessionInfo.name!}! You have successfully logged in.`,
        });
        history.push("/");
      })
      .catch((err) => {
        ErrorMessage(err)
          .then((msg) => showAlert({ type: "error", message: msg }))
          .catch((e) => console.log(e));
      });
  }, [authenticate, history, match.params.provider, session.api, showAlert]);

  console.log(history);
  return <div>Logging in...</div>;
});
