import React, { useReducer, createContext } from "react";
import { KafkaccServiceApi, SessionInfo } from "../client/api";

const SessionTokenKey = "Grpc-Metadata-Token";
const StorageTokenKey = "sessionToken";

const SessionToken = () => localStorage.getItem(StorageTokenKey);
const SetSessionToken = (token: string) =>
  localStorage.setItem(StorageTokenKey, token);
const ClearSessionToken = () => localStorage.removeItem(StorageTokenKey);

type SessionStoreState = {
  status: string;
  authenticated: boolean;
  sessionInfo: SessionInfo;
  requestOptions: () => object;
  api: KafkaccServiceApi;
  error?: string;
};

type SessionStoreAction =
  | { type: "success"; sessionInfo: SessionInfo }
  | { type: "error"; error: string };

const reducer = (
  state: SessionStoreState,
  action: SessionStoreAction
): SessionStoreState => {
  switch (action.type) {
    case "success":
      return {
        ...state,
        status: "authenticated",
        authenticated: true,
        sessionInfo: action.sessionInfo,
      };
    case "error":
      ClearSessionToken();
      return {
        ...state,
        status: "error",
        error: action.error,
        authenticated: false,
        sessionInfo: {},
      };
    default:
      throw new Error();
  }
};

export type SessionStoreDispatch = {
  state: SessionStoreState;
  dispatch: (action: SessionStoreAction) => void;
};

const SessionStoreContext = createContext({} as SessionStoreDispatch);

const SessionStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string;
  children: React.ReactNode[] | React.ReactNode | Element;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: "initial",
    requestOptions: () => ({
      headers: {
        "Grpc-Metadata-Token": SessionToken(),
        "Content-Type": "application/json",
      },
      onResponseHeader: (response: Response) => {
        let token = response.headers.get(SessionTokenKey);
        if (token !== null) {
          SetSessionToken(token);
        }
      },
    }),
    api: new KafkaccServiceApi({ basePath: baseUrl }),
    authenticated: false,
    sessionInfo: {},
  });
  return (
    <SessionStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </SessionStoreContext.Provider>
  );
};

export { SessionStoreContext, SessionStoreProvider, SessionToken };
