import React from "react";
import clsx from "clsx";
import { useTheme } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import BusinessIcon from "@material-ui/icons/Business";
import HomeIcon from "@material-ui/icons/Home";

import useReactRouter from "use-react-router";
import { useStyles } from "./user_drawer.styles";

export type BreadcrumbItem = [string, string] | string

const UserDrawer = ({
  breadcrumbs,
  children
}: {
  breadcrumbs?: BreadcrumbItem[]
  children: React.ReactNode[] | React.ReactNode;
}) => {
  const { history } = useReactRouter();

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  const homeClick = () => {
    history.push("/");
  };

  const topicsClick = () => {
    history.push("/topics");
  };

  const pushTo = (e: React.MouseEvent<any>, url: string) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(url)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          {breadcrumbs ? (
            <Breadcrumbs className={clsx(classes.navBreadcrumb)}>
              {breadcrumbs.map(bc =>
                typeof bc === 'string' ? (
                  <Typography variant="h6" noWrap key={bc}>
                    {bc}
                  </Typography>
                ) : (
                  <Typography variant="h6" noWrap key={bc[1]}>
                    <Link
                      href={bc[1]}
                      color="inherit"
                      onClick={(e: React.MouseEvent<any>) => pushTo(e, bc[1])}
                    >
                      {bc[0]}
                    </Link>
                  </Typography>
                )
              )}
            </Breadcrumbs>
          ) : (
            <Typography variant="h6" noWrap>
              Kafka Control Center
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key="Home" onClick={homeClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button key="Topics" onClick={topicsClick}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Topics" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default UserDrawer;