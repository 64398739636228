export const PrettyMilliseconds = (ms: number): string => {
  if (ms <= 0) {
    return ""
  }

  let result = Array<string>();
  let remainder = ms;

  const part = (step: number, name: string) => {
    const int = Math.floor(remainder / step);
    if (int > 0) {
      result.push("" + int + name)
    }
    remainder = remainder % step;
  }

  part(31557600000, "y")
  part(2629800000, "m")
  part(86400000, "d")
  part(3600000, "hr")
  part(60000, "min")
  part(1000, "s")
  part(1, "ms")

  return result.join(" ")
}