import React, { useContext } from "react";
import { withRouter } from "react-router";
import { Container, Box, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { AlertsContext, SessionStoreContext } from "../../state";
import { ErrorMessage } from "../../utils";

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext);
  const { state: session } = useContext(SessionStoreContext);

  function loginClick(_: any) {
    session.api
      .kafkaccServiceAuthURL("Google")
      .then(resp => {
        window.location.assign(resp.authURL!);
      })
      .catch(err => {
        ErrorMessage(err)
          .then(msg => showAlert({ type: "error", message: msg }))
          .catch(e => console.log(e));
      });
  }

  return (
    <Container maxWidth="sm">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Authentication required.
        </Typography>
        <Button color="primary" variant="contained" onClick={loginClick}>
          Login using Google
        </Button>
      </Box>
    </Container>
  );
});
