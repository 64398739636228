import React, { useEffect, useContext } from "react";
import { withRouter } from 'react-router'
import { TopicsStoreContext } from "../../state";
import TopicsTable from "../../components/table/topics";
import { UserDrawer } from "../../components/layout";
import { Topic } from "../../client/api";
import path_for from "../path_for";

const TopicsListRoute = withRouter(({ history }) => {
  const { state, dispatch } = useContext(TopicsStoreContext);

  useEffect(() => {
    state.api
      .kafkaccServiceListTopics()
      .then((response) => {
        dispatch({ type: "success", Topics: response.topics || [] });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: "error", error: err });
      });
  }, [state.api, dispatch]);


  const handleTopicClick = (e: React.MouseEvent<unknown>, topic: Topic) => {
    history.push(path_for.Topics(topic.name!))
  }

  return (
    <UserDrawer
      breadcrumbs={[['KafkaCC', path_for.Home()], 'Topics']}
    >
      <TopicsTable
        topics={state.Topics}
        onClick={handleTopicClick}
      />
    </UserDrawer>
  );
})

export default TopicsListRoute;