import React from "react";

import { AlertsProvider } from "./alerts";
import { SessionStoreProvider } from "./sessions";
import { TopicsStoreProvider } from "./topics";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export * from "./alerts";
export * from "./sessions";
export * from "./topics";

const AllProviders = ({
  baseUrl,
  children
}: {
  baseUrl: string;
  children: React.ReactNode[] | React.ReactNode;
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <AlertsProvider>
        <SessionStoreProvider baseUrl={baseUrl}>
          <TopicsStoreProvider baseUrl={baseUrl}>{children}</TopicsStoreProvider>
        </SessionStoreProvider>
      </AlertsProvider>
    </MuiPickersUtilsProvider>
  );
};

export default AllProviders;
