import React from "react";
import { Route, Switch } from "react-router-dom";
import Routes from "./routes";
import AllProviders from "./state";
import PrivateRoute from "./components/private_router";
import path_for from "./routes/path_for";

const BASE_URL = "/api";

function App() {
  return (
    <AllProviders baseUrl={BASE_URL}>
      <Switch>
        <Route exact path="/login" component={Routes.Sessions.Login} />
        <Route
          exact
          path={path_for.Auth(':provider')}
          component={Routes.Sessions.Auth}
        />
        <PrivateRoute exact path={path_for.Home()} component={Routes.Home} />

        <PrivateRoute exact path={path_for.Topics()} component={Routes.Topics.List} />
        <PrivateRoute exact path={path_for.Topics(':name')} component={Routes.Topics.Edit} />

        <Route component={Routes.NotFound} />
      </Switch>
    </AllProviders>
  );
}

export default App;
