import React, { useReducer, createContext } from "react";
import { Topic, KafkaccServiceApi } from "../client/api";

type TopicsStoreState = {
  status: string;
  api: KafkaccServiceApi;
  Topics: Topic[];
  error?: string;
};

type TopicsStoreAction =
  | { type: "success"; Topics: Topic[] }
  | { type: "error"; error: string }
  | { type: "add"; Topic: Topic };

const reducer = (
  state: TopicsStoreState,
  action: TopicsStoreAction
): TopicsStoreState => {
  switch (action.type) {
    case "add":
      return {
        ...state,
        status: "success",
        Topics: state.Topics.concat([action.Topic]),
      };
    case "success":
      return { ...state, status: "success", Topics: action.Topics };
    case "error":
      return { ...state, status: "error", error: action.error };
    default:
      throw new Error();
  }
};

export type TopicsStoreDispatch = {
  state: TopicsStoreState;
  dispatch: (action: TopicsStoreAction) => void;
};

const TopicsStoreContext = createContext({} as TopicsStoreDispatch);

const TopicsStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string;
  children: React.ReactNode[] | React.ReactNode | Element;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: "initial",
    api: new KafkaccServiceApi({ basePath: baseUrl }),
    Topics: [],
  });
  return (
    <TopicsStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </TopicsStoreContext.Provider>
  );
};

export { TopicsStoreContext, TopicsStoreProvider };
